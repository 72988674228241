







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({type: String, default: ''}) private popover!:string;
  @Prop({type: String, default: 'top-start'}) private placement!:string;
  @Prop({type: String, default: 'icon-help'}) private iconClass!:string; //el-icon-warning
}
