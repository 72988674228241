<template>
  <img v-bind="$attrs" v-on="$listeners" :src="load(source)" :srcset="srcset" />
</template>

<script>
  export default {
    props: {
      source: {
        type: String,
        required: true
      },
      with2x: {
        type: Boolean,
        default: true,
      },
      with3x: {
        type: Boolean,
        default: true,
      },
      withRequire: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      srcset() {
        let set = this.load(this.source);
        set += this.with2x ? `, ${this.load(this.file(2))} 2x` : '';
        set += this.with3x ? `, ${this.load(this.file(3))} 3x` : '';
        return set;
      },
    },
    methods: {
      file(num) {
        const [file, base, ext] = this.source.match(/(.*)(?:\.([^.]+$))/);
        return `${base}@${num}x.${ext}`;
      },
      load(file) {
        try {
          return this.withRequire ? require(`@/assets/${file}`) : file;
        } catch (ex) {
          if (ex.code === 'MODULE_NOT_FOUND') {
            return file;
          }
          throw ex;
        }
      }
    }
  }
</script>
