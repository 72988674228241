










import { Component, Prop, Vue } from 'vue-property-decorator';
import {IPaginator} from '@/contracts/i-paginator';
import Qs from "qs";

@Component
export default class extends Vue {
  @Prop({type: Object, required: true}) private paginator!: IPaginator<any>;

  @Prop({type: Boolean, default: true}) private withMargin!: boolean;

  private getCurrentGETParameters() { return Qs.parse(location.search.substring(1)) as any; }

  protected async paginate(nextPage:number = 1) {
    await this.$router.push({ query: { ...this.getCurrentGETParameters(), page: nextPage <= 1 ? undefined : nextPage } as any })
    this.$emit('paginate', nextPage);
  }
}
