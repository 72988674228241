



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({required: true}) private data!:string[];
  @Prop({required: true}) private label!:string;
  @Prop({default: ''}) private prop!:string;
  @Prop({default: ''}) private placeholder!:string;
  @Prop({default: ''}) private prefix!:string;

  private created() {
    if (this.data.length === 0) {
      this.addRow();
    }
  }
  private deleteRow(index: number) {
    this.data.splice(index, 1);
    if (this.data.length === 0) {
      this.addRow();
    }
  }
  private addRow() {
    this.data.push('');
  }
}
